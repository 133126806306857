import React from 'react';

import SEO from '../components/seo';
import { useTranslation, Trans } from 'react-i18next';
import PageHeader from '@ui/PageHeader';
import { Link } from 'gatsby';

export default function NotFoundPage() {
  const { t } = useTranslation();
  return (
    <>
      <SEO title="404: Not found" />
      <PageHeader className="pb-2">{t('404.sorry')}</PageHeader>
      <p className="pb-2">{t('404.oldLink')}</p>
      <hr className="pb-2" />
      <p className="pb-2">
        {/* prettier-ignore */}
        <Trans i18nKey="404.tryGoingBack">
          Try going back or
          <Link to="/">{{ link: 'return to the homepage.' }}</Link>
        </Trans>
      </p>
    </>
  );
}
